@import '~styles/variables';

.navWrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  background: $color-teal;
  overflow-x: hidden;
  flex: 1;
  padding: 1rem; }

.navHeader {
  color: $color-white;
  padding-left: 0.5rem; }

.navItem {
  color: $color-white;

  i {
    float: right; } }
