@import './variables';

body {
  margin: 0;
  color: #1d1d1d;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

// Semantic UI style overrides
.ui.header.white {
  color: #ffffff; }

.ui.secondary.menu {
  color: #ffffff; }
