$color-red            : #B03060;
$color-orange         : #FE9A76;
$color-yellow         : #FFD700;
$color-olive          : #32CD32;
$color-green          : #016936;
$color-teal           : #008080;
$color-blue           : #0E6EB8;
$color-violet         : #EE82EE;
$color-purple         : #B413EC;
$color-pink           : #FF1493;
$color-brown          : #A52A2A;
$color-grey           : #A0A0A0;
$color-black          : #000000;
$color-white          : #ffffff;
